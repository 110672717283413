/** @jsx jsx */
import { jsx } from "theme-ui";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Slide from "react-reveal/Slide";
import styled from "@emotion/styled";
import marsTheme from "../gatsby-plugin-theme-ui/marsTheme";
import { MarsLogoWhite, MarsTextLogoWhite } from "../hooks/images";
// Fluid sharp queries, I seperated them so we could query multiple files at once
//! using this query to bring in the stock images
export const fluidImage = graphql`
  fragment fluidImageFour on File {
    childImageSharp {
      fluid(fit: CONTAIN, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
export const Data = () => {
  const fourImage = useStaticQuery(
    graphql`
      query fourImageQuery {
        fourOhFour: file(relativePath: { eq: "MARS404D_404Desktop.jpg" }) {
          ...fluidImage
        }
      }
    `
  );
  return fourImage;
};

export const FourOhFour = () => {
  const image = Data();
  return (
    <StyledBackgroundLanding
      fluid={image.fourOhFour.childImageSharp.fluid}
      alt="Earth Landing Hero"
    />
  );
};
export const StyledBackgroundLanding = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  position: absolute !important;
`;
// TODO COmplete 404page
const FourOFourPage = () => {
  return (
    <div>
      <FourOhFour />
      <div
        sx={{
          position: `relative`,
        }}
      >
        <header>
          <nav>
            <ul
              sx={{
                position: `relative`,
                display: `flex`,
                justifyContent: `space-between`,
                width: `full`,
                listStyle: `none`,
                padding: `0`,
                mt: 0,
                zIndex: `50`,
                alignItems: `center`,
              }}
            >
              <Slide left delay={2000}>
                <li
                  sx={{
                    width: [`9rem`, `9rem`, `10rem`, `11rem`],
                    pl: [3, 3, 4, 4],
                  }}
                >
                  <MarsTextLogoWhite />
                </li>
              </Slide>
              <Slide right delay={2000}>
                <li
                  sx={{
                    width: [`3rem`, `3rem`, `5rem `, `6rem`],
                    pr: [3, 3, 4, 4],
                    transition: `2s ease`,
                  }}
                >
                  <Link to="/">
                    <MarsLogoWhite />
                  </Link>
                </li>
              </Slide>
            </ul>
          </nav>
        </header>
        <center>
          <div sx={{ width: `100%` }}>
            <h1
              sx={{
                fontSize: [`9rem`, `10rem`, `11rem`, `12rem`, `12rem`],
                fontFamily: `Libre Baskerville`,
                color: marsTheme.colors.clay,
                m: 0,
                lineHeight: `1`,
              }}
            >
              404
            </h1>
            <p
              sx={{
                color: marsTheme.colors.clay,
                m: 0,
                fontFamily: `Libre Baskerville`,
                fontSize: `1rem`,
              }}
            >
              PAGE NOT FOUND
            </p>
            <h2
              sx={{
                fontSize: [5, 5, 6, 7, 7],
                fontFamily: `Libre Baskerville`,
                color: marsTheme.colors.marsRed,
              }}
            >
              The Journey <br />
              Continues...
            </h2>
            <p
              sx={{
                fontSize: [`1rem`, 3, 4, 5, 6],
                fontFamily: `Libre Baskerville`,
                color: marsTheme.colors.clay,
                m: 0,
                lineHeight: `1.5`,
              }}
            >
              Let&#39;s find out what you&#39;ve <br />
              been looking for all along.
            </p>
            <div>
              <button
                sx={{
                  borderRadius: 50,
                  border: `none`,
                  width: [`50%`, `50`, `30%`, `25%`, `20%`],
                  p: 3,
                  // my: 4,
                }}
              >
                <Link to="/">
                  <em
                    sx={{
                      fontFamily: `Libre Baskerville`,
                      my: 1,
                      fontSize: 2,
                    }}
                  >
                    Enter
                  </em>
                </Link>
              </button>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};
export default FourOFourPage;
